import { NgModule } from '@angular/core';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from '../shared/app-layout/app-layout.component';


const routes: Routes = [
  { path: '', redirectTo: 'profile', pathMatch: 'full' },
  {
    path: 'profile',
    component: AppLayoutComponent,
    ...canActivate(() => redirectUnauthorizedTo(['/auth/login'])),
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'account',
    component: AppLayoutComponent,
    ...canActivate(() => redirectUnauthorizedTo(['/auth/login'])),
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
