import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ekipa/common-ui';


@Component({
  selector: 'ekipa-app-layout',
  templateUrl: './app-layout.component.html'
})
export class AppLayoutComponent {

  constructor(
    public authService: AuthService,
    public router: Router
  ) { }


}
