import { NgModule } from '@angular/core';
import { SettingsRoutingModule } from './settings-routing.module';


@NgModule({
  declarations: [],
  imports: [
    SettingsRoutingModule
  ],
})
export class SettingsModule { }
